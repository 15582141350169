/**
 * Funcion que convierte una direccion http a https
 * @param url url a convertir
 * @returns
 */
export const convertToHttps = (url: string): string => {
  if (url.startsWith('http://')) {
    return `https://${url.slice(7)}`;
  }

  return url;
};
