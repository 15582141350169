import { PagesItem } from 'components';
import { CICLO_FACTURACION } from 'navigation';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Routes from '../navigation/RouterConfig';

const RootCicloFacturacion = ({ path }: { path: string }) => {
  /**
   * history
   */
  const history = useHistory();
  /**
   * Uso del i18n para traducciones
   */
  const { t } = useTranslation();
  /**
   * Accion boton que retrocede a paso 1
   */
  const onClickVolver = () => {
    history.push(CICLO_FACTURACION);
  };
  return (
    <PagesItem
      titulo={t('tracking:paginas.tracking.cicloFacturacion.titulo')}
      path={path}
      textoVolver={t('tracking:paginas.tracking.cicloFacturacion.textoVolver')}
      onClickVolver={onClickVolver}
      dataTestId="pagina-principal"
    >
      <Routes />
    </PagesItem>
  );
};

export default RootCicloFacturacion;
