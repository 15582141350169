export const GRUPOSAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/grupo-saesa-logo.svg`
export const SAESA_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/saesa-logo.svg`
export const FRONTEL_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/frontel-logo.svg`
export const EDELAYSEN_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/edelaysen-logo.svg`;
export const LUZOSORNO_LOGO = `${process.env.REACT_APP_ASSETS_URL}/images/luz-osorno-logo.svg`;
export { default as imgLosMejores } from './the_best.png';
export { default as tooltipServicio } from './tooltip-numero-servicio.png';
export { default as homeTrendUp } from './home-trend-up.svg'; 
export { default as tramo } from './tramo.svg'; 
export { default as arrowRight } from './arrow-right.svg'; 
export { default as infoTramo } from './info-tramo.svg'; 
export { default as infoCircle } from './info-circle.svg'; 
export { default as tramoHomeTrend } from './tramo-home-trend.svg'; 
export { default as exportIcono } from './export.svg'; 
export { default as verUltimaBoleta } from './verUltimaBoleta.svg';
export { default as errorTramo } from './error-tramo.svg';
export { default as CLOSE_CIRCLE } from './close-circle.svg';

